import React, { useState } from 'react';
import Router from 'next/router';
import LoadingOverlay from '../components/Loading';

// import NProgress from 'nprogress';

import '../public/static/css/theme.css';
import '../public/static/css/custom.css';
// import '../global.scss';
import '../widget.scss';

export default function App({ Component, pageProps }) {
  const [loadState, setLoadState] = useState(false);

  Router.events.on('routeChangeStart', url => {
    setLoadState(true);
  });
  Router.events.on('routeChangeComplete', () => setLoadState(false));
  Router.events.on('routeChangeError', () => setLoadState(false));

  return (
    <>
      <LoadingOverlay isLoading={loadState} />
      <Component {...pageProps} />
    </>
  );
}
