import React from 'react';
import Spinner from 'react-bootstrap/Spinner';


const LoadingOverlay = ({ isLoading, title = "Đang tải" }) => (
  <div className={`loading__overlay ${isLoading ? 'is-active' : ''}`}>
    <div className="loading__target">
     <Spinner animation="border" />
      <div className="loading__text">{title}</div>
    </div>
  </div>
);
export default LoadingOverlay;
